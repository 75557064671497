/* Color palette */
:root {
  --white: #ffffff;
  --light-blue-grey: #c7cad0;
  --pale-blue: #e2e4e7;
  --pale-grey: #f3f6fd;
  --darkish-blue: #053589;
  --battleship-grey: #676f7c;
  --dark: #273342;
  --blue: #0f58d6;
  --blue-hover: #4f90ff;
  --grey-white: #f6f6f6;
  --red: #cc0000;
  --very-light-pink: #fae5e5;
  --pumpkin-orange: #ff8004;
  --salmon: #e57f7f;
  --jungle-green: #048942;
  --peach: #ffbf81;
  --silver: #b3dbc6;
  --golden-yellow: #ffca15;
  --light-beige: #ffefb8;
  --off-white: #fffae9;
  --orangey-yellow: #f5a623;
  --greenish-cyan: #50e3c2;
  --bright-orange: #ff5500;
  --powder-blue: #d5d8dc;
}

[class*="col-"] {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.clickable-block {
  cursor: pointer;
}

.div-flex {
  display: flex;
  width: 100%;
  //height: 100vh;
  //overflow: hidden;
}

#page-wrap {
  background-color: var(--grey-white);
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.date-picker-icon {
  position: relative;
  left: -95px;
  top: 3px;
}

.fleet-graph-element-hb > canvas {
  //height: 125px !important;
}

.bp3-button.bp3-minimal {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  height: 100%;
}

div.chart-legend {
  width: 10px;
  height: 10px;
  float: right;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  margin-top: 5px;
}

span.chart-legend-label-text {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.canvas-con {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.legend-con {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: justify;
  position: relative;
}
ul {
  list-style: none;
  padding: 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.verticalUL ul {
  display: block;
}

li {
  display: flex;
  float: Left;
}
li.span {
  display: block;
}

div.chart-legend {
  width: 15px;
  height: 15px;
  float: right;
  margin-left: 12px;
  margin-right: 12px;
  align-items: center;
  margin-top: 5px;
}

span.chart-legend-label-text {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

span.numero {
  font-weight: bold;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  //font-size: 0.95em;
  color: gray;
  margin-left: 15px;
  font-size: 12px;
}

span.categoria {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  //font-size: 0.75em;
  color: gray;
  font-size: 12px;
}

/* Loading */
.collapse-items {
  padding: 10px;
}

.event-fieldset {
  // background-color: var(--grey-white);
  padding-left: 25px;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 10px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--dark);
  margin-top: 5px;
}

.event-legend {
  width: auto !important;
  background-color: var(--white);
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
  padding: 5px;
  margin-bottom: 0px !important;
  margin-left: -15px !important;
}

.title-event-toggle {
  margin-bottom: 0px !important;
  margin-top: 5px !important;
}

.text-event-toggle {
  margin-bottom: 0px !important;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--dark);
}

.event-container {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 8px;
}

.event-container.container {
  margin-top: 20px;
  padding: 0 20px 20px 8px !important;
}

.div-fa-icon {
  margin-left: 10px;
}

.main-background {
  //box-shadow: inset 0 0px 1px 1px var(--light-blue-grey);
  background-color: var(--grey-white);
  //padding: 10px;
  //height: 100% !important;
}

.verticalHeight {
  max-height: calc(100vh - 115px - 60px - 15px - 24px);
}

.fullHeigth {
  height: 100% !important;
}
.main-page {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.main-page-fleet {
  //margin-top: -20px !important;
}

.marginTopSelect {
  margin-top: 10px !important;
}

.paddingLeft {
  padding-left: 30px !important;
}

.page-box {
  background-color: var(--white);
  display: flex;
  flex-wrap: wrap;
  //height: 100%;
  margin-bottom: 10px;
}

.mapHeight {
  min-height: 100vh;
}
.page-box-tab7 {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  flex: 1;
  //height: 100%;
  height: 100vh;
  //margin: 0px;
  //padding: 0px;
  //padding: 10px;
  //margin-bottom: 5px;
}
.page-box-tab-2 {
  flex: 1;
  //height: 50%;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.page-box-tab-2 .page-box-tab {
  //min-height: 37.5em !important;
  margin-top: 1px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.page-box-tab-3 {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  flex: 1;
  height: 90%;
  padding: 10px;
}

.fleetTabs .tab-content {
  width: 100% !important;
}

.background-simulation-page-box-tab {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
}

.page-box-fleet-map {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
}

.page-box-map-title {
  font-family: Arial;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
  padding: 9px 0px 5px 5px;
}

.page-box-map-main-title {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
  padding: 9px 0px 5px 5px;
}
.page-box-map-title1 {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
  padding: 9px 0px 5px 5px;
}

.page-box-top {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  margin-bottom: 10px !important;
}
.trainDetailsView {
  height: 170px;
}

.margin-element {
  margin-bottom: 10px;
  flex: 1;
}
.heightSensor {
  min-height: 15em;
  //transform: translateY(30px);
}
.resetMargins {
  margin: 0px !important;
}
.resetPadding {
  padding: 0px !important;
}
.d-flex {
  display: flex;
}
.flex1 {
  flex: 1;
}
.widthSpace {
  padding-left: 5%;
  padding-right: 5%;
}
.fleet-graph {
  flex: 1;
  background-color: var(--white);
  border-radius: 2px;
  //height: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.fleet-graph-20 {
  height: 20% !important;
}

.fleet-graph-80 {
  height: 80% !important;
}

.fleet-graph-element {
  flex: 1 !important;
}

.fleet-graph-element-hb {
  width: 85%;
  //height: 12em !important;
  margin: auto;

  font-family: Arial !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
  color: var(--battleship-grey) !important;
  padding: 10px;
}

.fleet-graph-title {
  //position: absolute;
  //width: 35%;
  width: 85%;
  //height: 38px;
  padding: 10px;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
}

.sensor-collection-measurements-title {
  text-align: left;
}

.fleet-graph-title-result {
  position: absolute;
  right: 5px;
  width: 35%;
  height: 38px;
  padding: 10px;
  font-family: Arial;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
}
.title-total-result {
  font-size: 1em;
}

.fleet-graph-sub-title {
  width: 94px;
  height: 15px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--dark);

  position: absolute;
  bottom: 0px;
  left: 80%;
}

.fleet-datetime-group {
  padding: 10px 15px 10px 20px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--dark);
}

.fleet-datetime-value {
  /*text-decoration:underline;
    display:inline-block;*/
}

.fleet-datetime {
}

.fleet-reference-data {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.fleet-reference-data-title {
  float: left;
  text-align: right;
  margin-top: 15px;
}

.fleet-reference-data-value {
  float: left;
  margin-top: 15px;
}

body {
  font-family: sans-serif;
  overflow-y: auto;
  height: 100%;
  height: 100vh;
}

.map img[title]:hover:after {
  color: red !important;
}

.map img:hover[title]:after {
  color: red;
}

.map img:hover[title] {
  color: red;
}

.map [title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -26px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}

.page-box-tab.map-wrapper {
  position: relative;
  top: 2px;
}

.map [title]:hover::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(107, 103, 103, 0.089);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation Is Loading Spinner*/

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* End loading */
html,
body,
#app,
#app > div {
  height: 100%;
  // height: 100vh;
  min-height: calc(100vh - 88px);
  width: 100%;
  margin: 0;
  background-color: var(--grey-white);
}

.full {
  height: 100%;
  width: 100%;
}

.logo {
  background-size: 149px 36px;
  width: 149px;
  height: 36px;
  float: right;
  margin: 40px;
}

.bgAuth {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}
//.authBG {
//  position: fixed;
//  top: -50%;
//  left: -50%;
//  width: 200%;
//  height: 200%;
//}

.authBG {
  background: url("images/skf-bg-login.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100%;
  height: 100vh;
}

.authBGImg {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: unset;
}
.errortext {
  color: red;
}
.imageRecommendations {
  width: 100%;
}
.loginTitle {
  text-align: center;
}
.loginBox {
  width: 560px;
  height: 520px;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.forgetBox {
  width: 560px;
  height: 520px;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.msgError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  position: absolute;
}
.noMarginBottom {
  margin-bottom: 0px !important;
}

.smallText {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--dark);
}

.centerBox {
  position: absolute;
  /*it can be fixed too*/
  left: 0;
  right: 0;
  top: 35px;
  bottom: 0;
  margin: auto;
  /*this to solve "the content will not be cut when the window is smaller than the content": */
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  width: 500px;
}

.SKFH1 {
  width: 480px;
  height: 36px;
  font-family: SKFChevinOT;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0f58d6;
}

.SKFH3 {
  font-family: SKFChevinOT;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0f58d6;
}

.fontMin {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0f58d6;
}

.customMargin {
  margin-top: 40px;
}

.customMargin2 {
  margin-top: 30px;
}

.customMargin3 {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 0px;
}

.inputlogin {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 0px !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid #0f58d6 !important;
  font-family: normal 16px / normal Arial, Helvetica, sans-serif;
  color: #0f58d6 !important;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2) inset;
  text-shadow: 0px 0px 0 rgba(255, 255, 255, 0.66);
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  width: 450px;
  max-width: 450px;
}

.buttonlogin {
  width: 150px;
  background-color: #0f58d6;
  height: 40px;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.buttonChangePassword {
  width: 250px;
  background-color: #0f58d6;

  height: 40px;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.buttonCancel {
  width: 150px;
  height: 40px;
  font-family: Arial !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #0f58d6 !important;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #0f58d6, inset 0 -1px 1px 0 rgba(16, 22, 26, 0.2);
  background-image: linear-gradient(to bottom, #fafafa, #fafafa),
    linear-gradient(to bottom, var(--white), rgba(255, 255, 255, 0));
}

.button-change-password {
  width: 170px;
  background-color: #0f58d6;
  height: 40px;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.button-send {
  width: 150px;
  height: 40px;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.button-cancel {
  width: 150px;
  height: 40px;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0f58d6;
}

.centerText {
  text-align: center;
}

.alarm-good-icon {
  text-align: center;
  margin: 14% 0 5% 0;
}

.form-info-text {
  width: 480px;
  height: 38px;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: var(--dark);
  margin: 20px 0 0 0;
}

.ICONAlarmGood {
  font-size: 140px;
  color: var(--jungle-green);
}

.password-count {
  bottom: 16px;
  right: 10px;
  font-size: 1rem;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}

.strength-meter:before,
.strength-meter:after {
  content: "";
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}

.strength-meter:before {
  left: calc(20% - 3px);
}

.strength-meter:after {
  right: calc(20% - 3px);
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength="0"] {
  width: 20%;
  background: darkred;
}

.strength-meter-fill[data-strength="1"] {
  width: 40%;
  background: orangered;
}

.strength-meter-fill[data-strength="2"] {
  width: 60%;
  background: orange;
}

.strength-meter-fill[data-strength="3"] {
  width: 80%;
  background: yellowgreen;
}

.strength-meter-fill[data-strength="4"] {
  width: 100%;
  background: green;
}

.subHeader {
  height: 120px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.vCenter {
  margin: auto;
}
.eventHeader {
  height: auto;
}
.mainHeader {
  font-family: Arial;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
  margin-left: 40px;
}

.dashboardHeader {
  font-family: Arial;
  font-size: 70px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 60px;
  letter-spacing: normal;
}

.customText {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
.customText12 {
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}
.customText12Bold {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.customText16 {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
}

.customText14 {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
}

.colorRed {
  color: #eb202a !important;
}

.customText18 {
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.bold {
  font-weight: bold !important;
}

.dark {
  color: var(--dark) !important;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginRight25 {
  margin-right: 25px;
}

.clearFilters {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0f58d6;
  cursor: pointer;
  margin-left: 8px !important;
}

a.customLink {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0f58d6;
  cursor: pointer;
}

.battleship {
  color: var(--battleship-grey);
}

.heightHeader {
  //height: 105px !important;
  height: 100% !important;
}
.ICONArrowChevronRight {
  width: 30px;
  height: 30px;

  object-fit: contain;
}

.bg-trains {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  height: 675px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  padding: 0px !important;
  float: left !important;
}

.white {
  height: 675px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
}

.eventsBg {
  background-color: var(--white) !important;
}

.eventsTable {
  width: 100%;
}

.eventsRow {
  background-color: white;
  border-top: 1px solid var(--light-blue-grey) !important;
  border-left: 1px solid var(--light-blue-grey) !important;
  border-right: 1px solid var(--light-blue-grey) !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  height: 40px;
}

.eventsRow:last-of-type {
  border-bottom: 1px solid var(--light-blue-grey) !important;
}

.eventsRow .showAlert {
  padding-left: 10px !important;
}

.redAlarm {
  box-shadow: inset 5px 0 0 0 #eb202a;
}

.amberAlarm {
  box-shadow: inset 5px 0 0 0 #ffd615;
}

.amber-color {
  color: #ffd615 !important;
}

.red-color {
  color: #eb202a !important;
}

.hidden-box {
  display: none !important;
}

.non-hidden-box {
  display: block !important;
}

.hidden-box-grey {
  background-color: lightgray;
}

.act-now-alarm {
  width: 64px !important;
  height: 20px !important;
  border-radius: 10.5px;
  background-color: #eb202a;
  text-align: center;
}

.act-now-text {
  font-family: Arial;
  //ont-size: 11px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27 !important;
  letter-spacing: normal;
  color: var(--white) !important;
  //padding: 3px !important;
  padding: 3px 3px !important;
}

.event-status-text {
  width: 43px;
  height: 15px;
  font-family: Arial;
  //font-size: 11px !important;
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25 !important;
  letter-spacing: normal;
  color: var(--dark) !important;
  padding: 4px !important;
}

.event-status {
  width: 71px !important;
  height: 20px;
  border-radius: 10.5px;
  background-color: #d8d8d8;
  text-align: center;
}

.eventTitle {
  font-family: Arial;
  //font-size: 13px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #808895;
}

.eventDesc {
  font-family: Arial;
  //font-size: 13px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--dark);
}

.mainPadding {
  padding: 30px;
}

.mainMargin {
  margin-top: 30px;
}

.mask {
  width: 7px;
  height: 11px;
  object-fit: contain;
}

.maskright {
  transform: rotate(180deg);
}

.customGrey {
  color: #808895;
}

.paddles {
}

.paddle {
  position: absolute;
  top: 43px;
  bottom: 0;
  width: 3em;
  border: 0;
  background: transparent;
  color: var(--darkish-blue);
  height: 82px;
  cursor: pointer;
}

.font16 {
  font-size: 38px !important;
}

.left-paddle {
  left: 0;
}

.right-paddle {
  right: 0;
}

.hidden {
  display: none;
}

.topBarMenu {
  height: 60px;
  box-shadow: 0 2px 4px 0 rgba(39, 51, 66, 0.2);
  background-color: #0f58d6 !important;
  padding: 0px 1rem !important;
}
.cursor {
  cursor: pointer;
}

.bp3-button {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white) !important;
}

.topmenu-active {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.logoMenu {
  width: 75px;
  height: 18px;
  object-fit: contain;
}

.brandMenu {
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 36px;
  position: relative;
  top: 2px;
}

.linkMenu {
  width: auto;
}

.linkMenu span.label-topnav {
  position: relative;
  top: 1px;
}

.iconMenu {
  margin-right: 6px;
}

.separator {
  border-left: 1px solid white;
  padding-left: 14px;
  height: 40% !important;
}

.dropdown-toggle {
  color: #ffffff !important;
}

.roundedImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.topmenu-dashboard {
}

.topmenu-fleet {
}

.topmenu-reports {
}

.topmenu-history {
}

.container-fluid {
  width: 100%;
  //height: 100%;
  // background-color: var(--grey-white);
}

.separatorCommon {
  border-right: 1px solid var(--light-blue-grey) !important;
}
.separatorCommonLeft {
  border-left: 1px solid var(--light-blue-grey) !important;
  max-width: 35px !important;
}

.infoWidth {
  max-width: 50px !important;
}

.minwidth {
  max-width: 35px !important;
}

.customPadding {
  padding-top: 10px;
  padding-bottom: 10px;
}
.customPaddingM {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.textCenter {
  text-align: center;
  font-size: 12px;
}

.dangerColor {
  color: #eb202a !important;
}

.warningColor {
  color: #ffd615 !important;
}

.btn-filter {
  background-color: var(--grey-white) !important;
  border-bottom: 1px solid var(--light-blue-grey) !important;
}

.infoBar {
  background-color: #ffffff;
  box-shadow: inset 0 0px 1px 1px var(--light-blue-grey);
}

.infoBar .assetsSeparator {
  height: 70px;
}

.infoBar .assetsSeparator::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  background-color: #ddd;
  height: 60px;
  width: 3px;
}

.infoBar span.whereAlarm {
  position: relative;
  top: 5px;
  left: -3px;
}

.posIconAlertRed {
  position: absolute;
  top: -3px;
  left: 5px;
}

.posIconAlert {
  position: absolute;
  top: -3px;
  left: -1px;
}

@media (max-width: 1024px) {
  .posIconAlertRed {
    position: absolute;
    top: 4px;
    left: 7px;
  }
  .posIconAlert {
    position: absolute;
    top: 1px;
    left: 3px;
  }
  .customerName span {
    font-size: 14px !important;
  }
  .vCenterResponsive {
    margin: 0 !important;
  }
}

@media (min-width: 768px) {
  //.dropdown button {
  //  width: 250px !important;
  //}
  // .dropdown-item {
  //   width: 250px !important;
  // }
  .dropdown-toggle::after {
    display: none !important;
  }
  .customerName {
    text-align: right !important;
  }
}

@media (max-width: 768px) {
  .customerName {
    text-align: left !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* iPad in landscape */
  .tab-content {
    height: 511px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* iPad in portrait */
  .tab-content {
    height: 995px !important;
  }
}

.alignRight {
  text-align: right;
}

.sideBox {
  width: 58px;
  height: 18px;
  border-radius: 2px;
  background-color: var(--battleship-grey);
  font-family: Arial;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  padding: 6px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem 0.75rem 1rem;
  margin-bottom: 0rem;
  list-style: none;
  background-color: var(--grey-white) !important;
  border-radius: 0.25rem;
}

.separatorMap {
  margin-right: 5px !important;
}

.navbar-nav {
  height: 100%;
}

.navbar-collapse {
  height: 100%;
}

.nav-tabs {
  border: 5px !important;
  margin-bottom: 6px;
}

.nav-link {
  padding: 1.25rem 0.65rem 1.25rem 0.65rem !important;
}

.nav-tabs .nav-link.active {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  color: var(--dark);
  border: 0px !important;
  border-bottom: 2px solid var(--dark) !important;
  background-color: transparent !important;
}

.nav-link {
  padding: 1.25rem 0.65rem 1.25rem 0.65rem !important;
}

.fleetTabs > a {
  padding: 2rem 1rem !important;
}

.reset-row-margins {
  margin-right: 0px !important;
  margin-left: 15px !important;
}

.hide-tabs {
  border-bottom: 3px solid transparent !important;
  pointer-events: none;
}

.hide-tabs a {
  border-bottom: 3px solid transparent !important;
  pointer-events: none;
}

.nav-tabs .nav-link .hide-tabs {
  border-bottom: 3px solid transparent !important;
}

div.nav-item a.hide-tabs.nav-link {
  border-bottom: 0px solid transparent !important;
  color: transparent !important;
}

.nav-item .hide-tabs {
  border-bottom: 3px solid transparent !important;
}

nav.fleetTabs.hide-tabs.nav.nav-tabs a.nav-item.nav-link {
  border-bottom: 3px solid transparent !important;
}

.nav-tabs .nav-link {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #808895;
  border: 0px !important;
  border-bottom: 2px solid var(--light-blue-grey) !important;
  padding-bottom: 5px !important;
  padding-top: 12px !important;
}

@media (max-width: 1024px) {
  .heightHeader {
    font-size: 12px !important;
    height: 100% !important;
  }
  .dashboardHeader {
    font-size: 40px !important;
  }
}

// Breadcumb

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  font-family: Arial !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
  color: var(--battleship-grey) !important;
}

.breadCrumbSKF {
  font-family: Arial !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
  color: var(--battleship-grey) !important;
}

.breadcrumb-item.active {
  color: var(--dark) !important;
  font-weight: bold !important;
  font-size: 12px;
}

.breadcrumb-item a {
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: var(--battleship-grey) !important;
  font-size: 12px;
}

.dropdown-toggle {
  font-family: Arial !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
  color: var(--dark) !important;
}

.customSelect {
  position: relative;
  display: inline-block;
  /* margin-bottom: 15px; */
  width: 100%;
}

.fleetFilter__menu {
  font-family: Arial !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
}

.fleetFilter__control {
  border: 0px solid !important;
  border-bottom: 1px solid var(--dark) !important;
  border-radius: 0px !important;
  background: var(--grey-white) !important;
  color: var(--dark) !important;
  font-family: Arial !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
}

.fleetFilter__indicator-separator {
  width: 0px !important;
}

.fleetFilter__dropdown-indicator {
  color: var(--dark);
}

.customSelect select {
  height: 34px;
  font-family: "Arial";
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px 5px;
  outline: 0;
  border: 0px solid;
  border-bottom: 1px solid var(--dark) !important;
  border-radius: 0px;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  background: var(--grey-white) !important;
  color: var(--dark);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.customSelect select::-ms-expand {
  display: none;
}

.customSelect select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.customSelectGrey select {
  border-bottom: 1px solid var(--light-blue-grey) !important;
}

.customSelectBlue select {
  border-bottom: 1px solid #0f58d6 !important;
}

.select_arrow {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 0px;
  height: 0px;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.customSelect select:hover ~ .select_arrow,
.customSelect select:focus ~ .select_arrow {
  border-color: #000000;
}

.customSelect select:disabled ~ .select_arrow {
  border-top-color: #cccccc;
}

.hasAlert {
  width: 10px;
  height: 10px;
  background-color: #eb202a;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
}

.MuiTooltip-tooltip-3 {
  font-family: Arial !important;
  font-size: 11px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.27 !important;
  letter-spacing: normal !important;
  color: var(--white) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04) !important;
  background-color: var(--dark) !important;
  width: 100px;
  height: 25px;
  text-align: center;
}

.marginDashboard {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.greyColor {
  background-color: var(--grey-white) !important;
}

.paddingGraph {
  padding: 10px !important;
}

.paddingRowGraph {
  padding-top: 0px !important;
  padding-left: 15px !important;
  padding-bottom: 5px !important;
  padding-bottom: 8px !important;
}

.graph-title {
  font-size: 12px;
}

.map {
  min-height: 27em !important;
  position: relative !important;
  margin-left: 10px;
  margin-right: 10px;
  width: 97% !important;
}

.Toastify__toast-container {
  color: red !important;
}
.Toastify__toast--success {
  background-color: #dce0e1 !important;
  color: #46962b !important;
}

.separatorH {
  height: 100% !important;
}

.gradient-low-label {
  color: var(--battleship-grey);
  font-size: 12px;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.sidebarMenu {
  z-index: 50;
  position: absolute;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  width: 200px;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  background-color: var(--white);
}

.openMenu {
  position: absolute;
  display: block;
  opacity: 1;
  top: 60px;
  right: 0px;
  height: auto;
  transition: opacity 1s ease-out;
  padding-right: 20px;
  margin-right: 15px;
}

.topSideBar {
  height: 250px;
}

.middleSideBar {
  border-top: 1px solid var(--battleship-grey);
  height: 500px;
  background-color: var(--grey-white);
}

.bottomSideBar {
  border-top: 1px solid var(--battleship-grey);
  height: 50px;
  background-color: var(--white);
}

.profileMenu {
  list-style: none;
  display: inline !important;
}

.profileMenu li {
  float: right;
  display: inline-block;
  border-bottom: 1px solid #d8d8d8;
  width: 160px;
  height: 30px;
  font-family: Arial;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #485a64;
  text-align: right;
  margin-top: 7px;
  margin-right: 7px;
}

.firstProfileItem {
  margin-top: 15px !important;
}

.cursorPointer {
  cursor: pointer;
}

.DayPickerInput input {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid var(--dark);
  margin-left: 10px;
  width: 100px;
  background: url(/images/ic_more.png) no-repeat scroll 80px 0px;
}

.tabsAsset {
  max-width: 48% !important;
  padding: 15px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin-left: 5px !important;
  margin-right: 5px !important;
  min-height: 192px;
}

.paddingAssetTab {
  margin-bottom: 16px;
  padding-left: 15px;
}

.vCenterGraph {
  display: flex;
  justify-content: center;
  align-items: center;
  // left: 258px;
  // top: 100px;
  // position: relative;
}

.posAbsolute  {
  position: absolute;
}

.DASHBOARD .assetCard h5.page-box-map-title {
  font-size: 14px;
}

.toolTipGoogleMaps {
  color: #ffffff;
  position: absolute;
  background-color: #000000;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  font-family: Arial, Helvetica;
  text-align: center;
  z-index: 1000;
}

.toolTipGoogleMaps:after {
  position: absolute;
  top: 43px;
  left: 60px;
  content: "";
  width: 30px;
  height: 15px;
  border-top: solid 15px #000000;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}

.DASHBOARD h4.page-box-map-title {
  font-size: 22px !important;
}

@media (min-width: 1100px) {
  .DASHBOARD .col-lg-6.container-left,
  .DASHBOARD .col-lg-6.container-right {
    flex: 49.5% !important;
    max-width: 49.5% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .DASHBOARD .custom-padding.col-12 {
    padding-left: 15px !important;
  }

  .DASHBOARD .map-container {
    margin-left: 0.5% !important;
  }
}

@media (max-width: 1100px) {
  .DASHBOARD .col-lg-6.container-left,
  .DASHBOARD .col-lg-6.container-right {
    flex: 100% !important;
    max-width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .DASHBOARD .map-container {
    margin-top: 30px;
  }
}

.DASHBOARD .dashboardSubHeader {
  font-family: Arial;
  margin-bottom: 0.35rem;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.filterTabsWrapper {
  padding-top: 15px;
}

.DASHBOARD .filterTabsWrapper .btn-filters {
  margin-bottom: 0 !important;
}

.DASHBOARD .filterTabsWrapper .btn-filters svg {
  margin-left: 6px;
}

.DASHBOARD .map {
  min-height: 33em !important;
  position: relative !important;
  margin-left: 10px;
  margin-right: 10px;
  width: 97% !important;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .DASHBOARD .assetCard .assetsSeparator {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
}

.DASHBOARD .assetCard .assetsSeparator::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  background-color: #ddd;
  height: 90%;
  width: 3px;
}

.DASHBOARD .assetCard .yellow {
  color: #ffd615;
}

.DASHBOARD .assetCard .red {
  color: #eb202a;
}

.DASHBOARD .main-background {
  // box-shadow: inset 0 0px 1px 1px var(--light-blue-grey);
  // background-color: var(--grey-white);
  padding-top: 0 !important;
  //height: 100% !important;
}

.DASHBOARD .page-box-map.map-wrapper > div {
  height: 100% !important;
}

.btn-filters {
  z-index: 30000;
  /*  background-color: transparent !important;
    border: 1px solid var(--dark) !important;
    color: var(--dark) !important;
    font-size: 12px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding-left: 17px !important;
    padding-right: 17px !important;
    margin-right: 7px !important;
    margin-bottom: 7+10px !important;
    border-radius: 16px !important; */

  //display: inline-flex;
  padding: 5px 10px !important;
  border: 1px solid darkgrey !important;
  border-radius: 10px !important;
  background-color: #fefefe !important;
  cursor: pointer !important;
  font-size: 12px !important;
  margin: 5px 10px 5px 0 !important;
}
.bubbleDashboard {
  color: lightgray !important;
}

.DayPicker-Caption {
  font-family: Arial !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.19 !important;
  letter-spacing: normal !important;
  color: var(--dark) !important;
}

.noEvents {
  margin: 50px;
  font-family: Arial !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.19 !important;
  letter-spacing: normal !important;
  color: var(--dark) !important;
}

.centerSensorCollection {
  margin-top: 0;
  padding: 15px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--dark);
}

.centerLoadingSensor {
  margin-top: 70px;
}

.boxLine {
  height: 100%;
  width: 100%;
}

.straightLine {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  border-left: 2px dashed grey;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -3px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  font-size: 12px;
}

.boxLine:hover .straightLine {
  visibility: visible;
  opacity: 1;
}
.boxLine:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.height60 {
  height: 60%;
}
.height30 {
  height: 30%;
}
.height35 {
  height: 35%;
}

//NEW STYLES
.page-box-map-main-title {
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
  padding: 9px 0px 5px 5px;
}
.page-box-tab {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  flex: 1;
  padding: 10px;
  margin: 15px !important;
  //margin-top: 5%;
  //margin-bottom: 5%;
}
.page-box-shadow {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
    0 1px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  flex: 1;
}
.lastDays {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--dark);
  padding: 12px 0px 5px 5px;
  text-align: right;
  color: var(--battleship-grey);
}

.gradient-high-label {
  text-align: right;
  color: var(--battleship-grey);
  font-size: 12px;
}

.gradient-container {
  width: 90% !important;
}

.gradient {
  background-image: linear-gradient(
    to right,
    #50e3c2,
    #0f58d6,
    #053589,
    indigo
  );
  height: 20px;
}
.containerMap {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.dashboardHeader {
  font-family: Arial;
  font-size: 70px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 60px;
  letter-spacing: normal;
}

.DASHBOARD table,
.DASHBOARD form {
  font-size: 12px;
}

.DASHBOARD form .select {
  font-size: 12px;
  color: rgb(103, 111, 124);
  border-bottom: solid 2px var(--light-blue-grey);
}

.DASHBOARD table.table-sm td,
.DASHBOARD table.table-sm th {
  padding: 0.5rem;
}

.DASHBOARD table thead {
  background-color: #eae8e8;
  font-size: 14px;
}

.DASHBOARD table tbody .highlighted {
  color: #0f58d6;
}

.DASHBOARD table tbody .highlighted:hover {
  cursor: pointer;
}

.DASHBOARD table thead {
  border-left: solid 5px #eae8e8;
  background-color: #eae8e8;
}

.DASHBOARD table thead th {
  background-color: #eae8e8;
}

.DASHBOARD table tr {
  background-color: #ffffff;
  cursor: pointer;
}

.DASHBOARD table tbody tr.alarmMarker.red {
  border-left: solid 5px #eb202a;
}

.DASHBOARD table tbody tr.alarmMarker.yellow {
  border-left: solid 5px #ffd615;
}
.DASHBOARD table tbody tr.alarmMarker.noEvents {
  border-left: solid 5px #676f7c;
}

.tableRowRed {
  border-left: solid 5px #eb202a;
}
.tableRowAmbar {
  border-left: solid 5px #ffd615;
}
.tableRowGray {
  border-left: solid 5px #676f7c;
}

.DASHBOARD table tbody tr.alarmMarker.grey {
  border-left: solid 5px var(--battleship-grey);
}

.DASHBOARD table tbody tr.alert::before {
  display: block;
  position: absolute;
  top: 0;
  content: "";
  height: 100%;
  width: 3px;
}

.DASHBOARD table tbody tr.alarmMarker.red td.textAlarm span {
  background-color: #eb202a;
  color: var(--white);
  padding: 1px 9px 3px 9px;
  border-radius: 10px;
}

.DASHBOARD table tbody tr.selected,
.DASHBOARD table tbody tr.selected a,
.DASHBOARD table tbody tr.selected .highlighted {
  background-color: #0f58d6;
  color: var(--white) !important;
}

.DASHBOARD table tbody tr:hover,
.DASHBOARD table tbody tr:hover a,
.DASHBOARD table tbody tr:hover .highlighted {
  background-color: var(--blue-hover);
  color: var(--white) !important;
}

.DASHBOARD table tbody tr td a {
  cursor: pointer;
}

.DASHBOARD table tbody tr td a.highlighted {
  font-weight: bold !important;
}

.DASHBOARD table tbody tr.alert.red::before {
  background-color: #eb202a;
}

.DASHBOARD table tbody tr.alert.yellow::before {
  background-color: #ffd615;
}
.halfPage {
  width: 49.9%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 15px;
}
.dividedPage1 {
  width: 70% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.dividedPage2 {
  width: 30% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.height5 {
  height: 5vh;
}
.height25 {
  height: 25vh;
}
.height75 {
  height: 75vh;
}
.height100 {
  height: 100vh;
}
@media (max-width: 1024px) {
  .DayPickerInput {
    flex: 9;
  }
  #chartsTrends {
    width: 70%;
  }
}
.verticalMargin {
  transform: translateY(30px);
}
#space {
  height: 344px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
// @media (min-width:960) and (max-width: 1210px){
//   #trainRow {
//     margin-top: 90px;
//   }

// }
.maxHeight {
  height: 195px;
}
.dFlex {
  display: flex !important;
  flex-direction: column !important;
}
.dFlexRow {
  display: flex !important;
  flex-direction: row !important;
}
.removeMarginTop {
  margin-top: 0px !important;
}
.addMarginBottom {
  margin-bottom: 10px !important;
}
/* Custom Height for Dashboard*/
.customHeightDashboard {
  min-height: calc(100vh - 115px - 60px - 15px);
}
@media (min-width: 1200px) {
  .customHeightDashboard {
    min-height: calc(100vh - 115px - 60px - 15px);
  }
}
@media (max-width: 1024px) {
  .customHeightDashboard {
    height: calc(100% - 30px);
  }
}

.customHeightFleet {
  height: calc(100% - 75px);
}
.customHeightVehicle {
  height: calc(100vh - 176px);
}
.marginBottomCustom {
  margin-bottom: 15px;
}

.fleetMapCol {
  margin-top: 18px;
}

.fleetleftContainer {
  margin-top: 20px;
}

/* AGREGAR CLASE*/
.tab-content {
  padding-left: 15px;
  height: 657px;
  overflow: auto;
}

.translateX {
  transform: translateX(4px);
}
.marginLeft {
  margin-left: 0px !important;
}

.btn-primary {
  border-color: #0f58d6 !important;
  background-color: #0f58d6 !important;
}
.sfk_spinner {
  border: 5px solid #1859cc !important;
  border-right-color: transparent !important;
}
.skf_tiny_spinner {
  border: 3px solid #1859cc !important;
  border-right-color: transparent !important;
}
.canvasForCharts {
  width: 100% !important;
}

.vehicleEventIcon {
  margin: auto 0.2rem;
}
.vehicleEventIcon:hover {
  filter: hue-rotate(594deg) brightness(230%) contrast(80%);
}

.vehicleEventIconSelected {
  filter: hue-rotate(594deg) brightness(230%) contrast(80%);
}
